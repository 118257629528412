export default {
  data() {
    return {
      accommodationType2Const: [
        {label: 'Studio', value: 'STUDIO'},
        {label: 'T2', value: 'T2'},
        {label: 'T3', value: 'T3'},
        {label: 'T4', value: 'T4'},
        {label: 'T5', value: 'T5'},
        {label: 'T6', value: 'T6'},
        {label: 'T7', value: 'T7'},
        {label: 'T8', value: 'T8'},
        {label: 'T9', value: 'T9'},
        {label: 'T10', value: 'T10'},
        {label: this.$t('undefined'), value: undefined},
      ],
      accommodationType2ConstForProspect: [
        {label: 'Studio', value: 'STUDIO'},
        {label: 'T2', value: 'T2'},
        {label: 'T3', value: 'T3'},
        {label: 'T4', value: 'T4'},
        {label: 'T5', value: 'T5'},
        {label: 'T6', value: 'T6'},
        {label: 'T7', value: 'T7'},
        {label: 'T8', value: 'T8'},
        {label: 'T9', value: 'T9'},
        {label: 'T10', value: 'T10'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForAccommodationType2Const(value) {
      let result = this.accommodationType2Const.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getAccommodationType2ConstForProspect() {
      return this.accommodationType2ConstForProspect;
    },
  },
};
