<script>
/* COMPOSANTS */
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';
import GaContentView from '@components/v2/layout/GaContentView';
import AkDialogConfirm from '@components/general/AkDialogConfirm.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import ActivityCreateSidebar from '@views/activity/ActivityCreateSidebar.vue';
import ActivityUpdateSidebar from '@views/activity/ActivityUpdateSidebar.vue';
import ActivityDetailSidebar from '@views/activity/ActivityDetailSidebar';

/* MIXINS */
import activityContactWayConst from '@mixins/const/activityContactWayConst';
import activityMotifConst from '@mixins/const/activityMotifConst';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import ticketMixin from '@mixins/ticketMixin';
import ticketPriorityConst from '@mixins/const/ticketPriorityConst';
import ticketStatusConst from '@mixins/const/ticketStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import activityService from '@services/activityService';
import ticketService from '@services/ticketService';

export default {
  components: {
    AkButtonAction,
    ActivityCreateSidebar,
    ActivityUpdateSidebar,
    AkDialogConfirm,
    ActivityDetailSidebar,
    GaContentView,
    AkDatatablePagination,
  },
  mixins: [
    activityContactWayConst,
    activityMotifConst,
    permissionsMixin,
    randomRef,
    ticketMixin,
    ticketPriorityConst,
    ticketStatusConst,
    utilsMixin,
    messageMixin,
  ],
  props: {},
  data() {
    return {
      loading: true,
      loadingActivity: true,
      ownerId: undefined,
      listActivities: [],
      listTickets: [],
      itemActivity: {},
      currentDetailActivityId: undefined,
    };
  },
  computed: {},
  mounted() {
    this.ownerId = parseInt(this.$route.params.ownerId);
    this.reload();
  },
  methods: {
    goToTicket(event) {
      let data = event.data;
      this.$router.push({name: 'ticketDetails', params: {ticketId: data.id}, hash: '#prospect'});
    },
    openCreateSidebar() {
      if (this.$refs.sidebarCreate) this.$refs.sidebarCreate.show();
    },
    openUpdateSidebar(data) {
      if (this.$refs.sidebarUpdate) this.$refs.sidebarUpdate.show(data.id);
    },
    openDetailSidebar(data) {
      if (this.$refs.sidebarDetail) this.$refs.sidebarDetail.show(data.id);
    },
    reload() {
      this.loading = true;

      let p1 = activityService.activitys({interlocutorId: this.ownerId, interlocutorType: 'PROSPECT'});
      p1.then((data) => {
        this.listActivities = data;
      }).finally(() => {
        this.loadingActivity = false;
      });

      if (this.isGasGav()) {
        let p2 = ticketService.ticketsValidated({prospectId: this.ownerId});
        p2.then((data) => {
          this.listTickets = data;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    deleteActivity() {
      activityService
        .delete(this.itemActivity)
        .then((data) => {
          this.removeInListById(this.listActivities, data.id);
          this.success(this.$t('activity.deleted'));
        })
        .catch(() => {
          this.addError(this.$t('unknow_error'));
        })
        .finally(() => {
          this.itemActivity = undefined;
          this.$refs.dialogDelete.hide();
        });
    },
    openDeleteDialog(itemActivity) {
      this.itemActivity = itemActivity;
      this.$refs.dialogDelete.show();
    },
  },
};
</script>

<template>
  <GaContentView>
    <template #content>
      <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-2" v-if="this.isGasGav()">
          <span class="title-border">
            {{ $t('accommodation.ticket') }}
          </span>

          <AkDatatablePagination
            :loading="loading"
            :value="listTickets"
            class="cursor-pointer"
            :lazy="false"
            @row-click="goToTicket($event)">
            <template #empty>
              {{ $t('ticket.empty') }}
            </template>

            <Column :header="$t('ticket.label')" :sortable="true" sort-field="display">
              <template #body="slotProps">
                {{ slotProps.data.display }}
              </template>
            </Column>
            <Column :header="$t('ticket.dateCreated')" :sortable="true" sort-field="dateCreated">
              <template #body="slotProps">
                {{ displayDateTime(slotProps.data.dateCreated) }}
              </template>
            </Column>
            <Column :header="$t('ticket.validatedDate')" :sortable="true" sort-field="validatedDate">
              <template #body="slotProps">
                {{ displayDateTime(slotProps.data.validatedDate) }}
              </template>
            </Column>
            <Column :header="$t('ticket.employee')" :sortable="true" sort-field="employeeDisplay">
              <template #body="slotProps">
                {{ slotProps.data.employeeDisplay }}
              </template>
            </Column>
          </AkDatatablePagination>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex justify-between">
            <span class="title-border">
              {{ $t('accommodation.activity') }}
            </span>
            <AkButtonAction :label="$t('activity.add')" @click="openCreateSidebar()" />
          </div>
          <AkDatatablePagination
            :loading="loadingActivity"
            :value="listActivities"
            class="cursor-pointer"
            :lazy="false"
            @row-click="openDetailSidebar($event.data)">
            <template #empty>
              {{ $t('activity.empty') }}
            </template>

            <Column :header="$t('activity.dateHour')" :sortable="true" sort-field="date" style="width: 150px">
              <template #body="slotProps">
                {{ displayDateTime(slotProps.data.date) }}
              </template>
            </Column>
            <Column :header="$t('activity.who')" :sortable="true" style="width: 200px" sort-field="whoDisplay">
              <template #body="slotProps">
                {{ slotProps.data.whoDisplay }}
              </template>
            </Column>
            <Column :header="$t('activity.contactWay')" style="width: 200px">
              <template #body="slotProps">
                {{ displayForActivityContactWayConst(slotProps.data.contactWay) }}
              </template>
            </Column>
            <Column :header="$t('activity.motif')">
              <template #body="slotProps">
                {{ displayForActivityMotifConst(slotProps.data.motif) }}
              </template>
            </Column>
            <Column header-style=" width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="cursor-pointer" @click.stop="openDetailSidebar(slotProps.data)">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </span>

                  <span class="cursor-pointer" @click.stop="openUpdateSidebar(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>

                  <span v-if="this.isGasGav()" class="cursor-pointer" @click.stop="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-red" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </div>
      </div>

      <AkDialogConfirm
        ref="dialogDelete"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :message="$t('activity.confirm_delete')"
        :validate-label="$t('yes')"
        @yes="deleteActivity()" />

      <ActivityDetailSidebar ref="sidebarDetail" />
      <ActivityCreateSidebar ref="sidebarCreate" :itemId="ownerId" interlocutorType="PROSPECT" @creation="reload" />
      <ActivityUpdateSidebar ref="sidebarUpdate" @update="this.reload" />
    </template>
  </GaContentView>
</template>
