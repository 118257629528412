export default {
  data() {
    return {
      activityContactWayConst: [
        {label: 'Appel', value: 'APPEL'},
        {label: 'Email', value: 'EMAIL'},
        {label: 'Sms', value: 'SMS'},
        {label: 'Courrier', value: 'COURRIER'},
        {label: 'Visite en agence', value: 'VISITE_EN_AGENCE'},
        {label: 'Visite au logement', value: 'VISITE_AU_LOGEMENT'},
        {label: 'Whats app', value: 'WHATS_APP'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForActivityContactWayConst(value, returnLabel = '') {
      let result = this.activityContactWayConst.find((el) => el.value === value);
      return result ? result.label : returnLabel;
    },
  },
};
