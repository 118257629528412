<script>
/* COMPOSANTS */
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkFicListColRight from '@components/v2/general/AkFicListColRight.vue';

/* MIXINS */
import activityContactWayConst from '@mixins/const/activityContactWayConst';
import activityMotifConst from '@mixins/const/activityMotifConst';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import activityService from '@services/activityService';
import ficService from '@services/ficService';

export default {
  components: {
    AkFicListColRight,
    AkSidebar,
  },
  mixins: [activityContactWayConst, activityMotifConst, permissionsMixin, utilsMixin, loaderMixin, messageMixin],
  emits: ['hide'],
  data() {
    return {
      activityId: undefined,
      activity: undefined,
      files: [],
      detailSidebarVisible: false,
      loading: true,
    };
  },
  methods: {
    show(id) {
      this.activityId = id;
      this.reload();
      this.detailSidebarVisible = true;
    },
    hide() {
      this.detailSidebarVisible = false;
      this.activity = undefined;
      this.files = [];
      this.$emit('hide');
    },
    reload() {
      this.loading = true;

      Promise.all([
        activityService.activity(this.activityId).then((data) => {
          this.activity = data;
        }),
        ficService.fics({tableName: 'activity', tableId: this.activityId}).then((data) => {
          this.files = data;
        }),
      ]).then(() => (this.loading = false));
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="detailSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    block-scroll
    position="right"
    @hide="hide">
    <template #container>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('activity.activity') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar v-if="!loading" class="h-full flex flex-col gap-4 md:gap-2">
          <div class="grid grid-cols-2 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('activity.interlocutor') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                activity.interlocutorDisplay ?? '---'
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('activity.dateHour') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                displayDateTime(activity.date) ?? '---'
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('activity.who') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                activity.whoDisplay ?? '---'
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('activity.contactWay') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                displayForActivityContactWayConst(activity.contactWay, '---')
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('activity.motif') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                displayForActivityMotifConst(activity.motif, '---')
              }}</span>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('activity.comment') }}</span>
              <span class="v-html" v-html="activity.comment ?? '---'" />
            </div>
          </div>

          <AkFicListColRight
            v-if="files.length > 0"
            :can-upload="false"
            :can-delete="false"
            v-model="files"
            :table-id="activityId"
            :title="$t('activity.files')"
            table-name="activity" />
        </perfect-scrollbar>
        <div v-else class="flex flex-col items-center gap-2 pt-4">
          <span class="spinner primary xl" />
          <span class="text-gray font-normal font-inter">Récuperation en cours...</span>
        </div>
      </div>
    </template>
  </AkSidebar>
</template>

<style scoped>
.v-html :deep(*) {
  @apply font-inter font-medium text-gray text-md break-words;
}
</style>
