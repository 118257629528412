<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkFilesUpload from '@components/v2/input/AkFilesUpload.vue';
import AkInputEditor from '@components/v2/input/AkInputEditor.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import activityContactWayConst from '@mixins/const/activityContactWayConst';
import activityInterlocutorTypeConst from '@mixins/const/activityInterlocutorTypeConst';
import activityMotifConst from '@mixins/const/activityMotifConst';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import employeeAutocompleteMixin from '@mixins/autocomplete/employeeAutocompleteMixin';
import userAutocompleteMixin from '@mixins/autocomplete/userAutocompleteMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import activityService from '@services/activityService';
import reservationService from '@services/reservationService';

export default {
  components: {
    AkButtonFooterSidebar,
    AkInputEditor,
    AkAutocomplete,
    AkCalendar,
    AkSidebar,
    AkDropdown,
    AkFilesUpload,
  },
  mixins: [
    activityContactWayConst,
    activityInterlocutorTypeConst,
    activityMotifConst,
    permissionsMixin,
    utilsMixin,
    loaderMixin,
    ownerAutocompleteMixin,
    clientAutocompleteMixin,
    employeeAutocompleteMixin,
    userAutocompleteMixin,
    messageMixin,
  ],
  props: {
    interlocutorType: {type: String, default: undefined},
    itemId: {type: Number, default: undefined},
  },
  emits: ['creation'],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      activity: {
        date: new Date(),
      },
      files: [],
      accommodations: [],
      reservations: [],
      createSidebarVisible: false,
      submitted: false,
    };
  },
  validations() {
    return {
      activity: {
        interlocutorType: {required: requiredIf(!this.interlocutorType)},
        date: {required},
        contactWay: {required},
        motif: {required},
        comment: {required},
        interlocutorId: {required: requiredIf(!this.itemId)},
        ownerId: {required: requiredIf(this.isOwnerIdRequired)},
        clientId: {required: requiredIf(this.isClientIdRequired)},
      },
    };
  },
  mounted() {
    if (this.isHousekeeper()) this.activity.who = this.myUserId();
  },
  methods: {
    show() {
      this.createSidebarVisible = true;
      this.load();
    },
    hide() {
      this.createSidebarVisible = false;
      this.activity = {
        date: new Date(),
      };
      this.files = [];
    },
    load() {
      if (this.interlocutorType && this.itemId) {
        this.activity.interlocutorId = this.itemId;
      }
      if (this.isHousekeeper()) this.activity.who = this.myUserId();
    },
    create() {
      if (!this.activity.interlocutorId && this.itemId) this.activity.interlocutorId = this.itemId;
      if (!this.activity.interlocutorType && this.interlocutorType)
        this.activity.interlocutorType = this.interlocutorType;
      this.submitted = true;

      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      if (this.activity.interlocutorType === 'ACCOMMODATION') {
        this.activity.accommodationId = this.activity.interlocutorId;
      }
      if (this.activity.interlocutorType === 'RESERVATION') {
        this.activity.reservationId = this.activity.interlocutorId;
      }

      activityService
        .create(this.activity, this.files)
        .then(() => {
          this.$emit('creation');
          this.success(this.$t('activity.added'));
        })
        .catch(() => {
          this.addError(this.$t('unknow_error'));
        })
        .finally(() => {
          this.submitted = false;
          this.hide();
        });
    },
    loadAccommodationsForCustomer() {
      this.accommodations = [];
      let search = {ownerId: this.activity.ownerId};
      accommodationService.accommodations(search).then((data) => {
        this.accommodations = data;
      });
    },
    loadReservationsForVoyageur() {
      this.reservations = [];
      let search = {clientId: this.activity.clientId};
      reservationService.reservations(search).then((data) => {
        this.reservations = data;
      });
    },
  },
  computed: {
    isOwnerIdRequired() {
      return this.activity.interlocutorType === 'ACCOMMODATION';
    },
    isClientIdRequired() {
      return this.activity.interlocutorType === 'RESERVATION';
    },
    activityInterlocutorTypeOptions() {
      if (this.isHousekeeper()) return this.activityInterlocutorTypeForHousekeeperConst;
      return this.activityInterlocutorTypeConst;
    },
  },
  watch: {
    'activity.ownerId'(e) {
      if (e && this.activity.interlocutorType === 'ACCOMMODATION') {
        this.loadAccommodationsForCustomer();
      } else if (e === null) {
        this.activity.accommodationId = null;
        this.accommodations = [];
      }
    },
    'activity.clientId'(e) {
      if (e && (this.activity.interlocutorType === 'RESERVATION' || this.activity.interlocutorType === 'VOYAGEUR')) {
        this.loadReservationsForVoyageur();
      } else if (e === null) {
        this.activity.reservationId = null;
        this.reservations = [];
      }
    },
    'activity.interlocutorType'() {
      this.activity.interlocutorId = null;

      this.accommodations = [];
      this.reservations = [];
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="createSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    block-scroll
    position="right"
    @hide="hide">
    <template #container>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('activity.add') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4 md:gap-2">
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <template v-if="!interlocutorType">
              <AkDropdown
                v-model="activity.interlocutorType"
                :label="$t('activity.interlocutorType')"
                :options="this.activityInterlocutorTypeOptions"
                :submitted="submitted"
                :validator="v$.activity.interlocutorType" />

              <template v-if="activity.interlocutorType === 'ACCOMMODATION'">
                <AkAutocomplete
                  v-model="activity.ownerId"
                  :init-method="autocompleteOwnerById"
                  :label="$t('owner.ownerDisplay')"
                  :search-method="autocompleteOwner"
                  :search-object="{ownerStatus: 'CUSTOMER'}"
                  :submitted="submitted"
                  option-label="display"
                  option-value="id"
                  :validator="v$.activity.ownerId" />
                <AkDropdown
                  v-model="activity.interlocutorId"
                  :label="$t('activity.accommodation')"
                  :options="accommodations"
                  :placeholder="$t('activity.accommodation')"
                  :submitted="submitted"
                  :validator="v$.activity.interlocutorId"
                  option-label="name"
                  option-value="id" />
              </template>

              <template v-if="activity.interlocutorType === 'RESERVATION'">
                <AkAutocomplete
                  v-model="activity.clientId"
                  :init-method="autocompleteClientById"
                  :label="$t('activity.voyageur')"
                  :search-method="autocompleteClient"
                  :submitted="submitted"
                  option-label="display"
                  option-value="id"
                  :validator="v$.activity.clientId" />
                <AkDropdown
                  v-model="activity.interlocutorId"
                  :label="$t('activity.reservation')"
                  :options="reservations"
                  :placeholder="$t('activity.reservation')"
                  :submitted="submitted"
                  :validator="v$.activity.interlocutorId"
                  option-label="display"
                  option-value="id" />
              </template>

              <AkAutocomplete
                v-if="activity.interlocutorType === 'PROPRIETAIRE'"
                v-model="activity.interlocutorId"
                :init-method="autocompleteOwnerById"
                :label="$t('owner.ownerDisplay')"
                :search-method="autocompleteOwner"
                :search-object="{ownerStatus: 'CUSTOMER'}"
                :submitted="submitted"
                :validator="v$.activity.interlocutorId"
                option-label="display"
                option-value="id" />
              <AkAutocomplete
                v-if="activity.interlocutorType === 'PROSPECT'"
                v-model="activity.interlocutorId"
                :init-method="autocompleteOwnerById"
                :label="$t('ticket.prospect_display')"
                :search-method="autocompleteOwner"
                :search-object="{ownerStatus: 'PROSPECT'}"
                :submitted="submitted"
                :validator="v$.activity.interlocutorId"
                option-label="display"
                option-value="id" />
              <AkAutocomplete
                v-if="activity.interlocutorType === 'VOYAGEUR'"
                v-model="activity.interlocutorId"
                :init-method="autocompleteClientById"
                :label="$t('activity.voyageur')"
                :search-method="autocompleteClient"
                :submitted="submitted"
                :validator="v$.activity.interlocutorId"
                option-label="display"
                option-value="id" />
              <AkAutocomplete
                v-if="activity.interlocutorType === 'SALARIE'"
                v-model="activity.interlocutorId"
                :init-method="autocompleteEmployeeById"
                :label="$t('activity.salarie')"
                :search-method="autocompleteEmployee"
                :submitted="submitted"
                :validator="v$.activity.interlocutorId"
                option-label="display"
                option-value="id" />
            </template>

            <AkCalendar
              v-model="activity.date"
              :label="$t('activity.dateHour')"
              :submitted="submitted"
              :validator="v$.activity.date"
              show-time />
            <AkAutocomplete
              v-if="this.isGasGav()"
              v-model="activity.who"
              :init-method="autocompleteUserById"
              :label="$t('activity.who')"
              :search-method="autocompleteUser"
              :search-object="{enabled: true}"
              :submitted="submitted"
              option-label="display"
              option-value="id" />
            <AkDropdown
              v-model="activity.contactWay"
              :label="$t('activity.contactWay')"
              :options="activityContactWayConst"
              :submitted="submitted"
              :validator="v$.activity.contactWay" />
            <AkDropdown
              v-model="activity.motif"
              :label="$t('activity.motif')"
              :options="activityMotifConst"
              :submitted="submitted"
              :validator="v$.activity.motif" />
            <AkInputEditor
              v-model="activity.comment"
              :label="$t('activity.comment')"
              :rows="13"
              :submitted="submitted"
              :validator="v$.activity.comment"
              style="white-space: pre" />
          </div>

          <AkFilesUpload v-model="files" :label="$t('activity.files')" :submitted="submitted" multiple />

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancel-label="$t('cancel')"
            :validate-label="$t('add')"
            @action="create()"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
