export default {
  data() {
    return {
      habitatTypeConst: [
        {label: this.$t('accommodation.maisonIndividuelle'), value: 'MAISON_INDIVIDUELLE'},
        {label: this.$t('accommodation.maisonEnCopro'), value: 'MAISON_EN_COPRO'},
        {label: this.$t('accommodation.appartImmeubleCollectif'), value: 'APPART_IMMEUBLE_COLLECTIF'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForHabitatTypeConst(value) {
      let result = this.habitatTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
