<script>
/* COMPOSANTS */
import AkContactProspect from '@views/prospect/components/AkContactProspect';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputNumber from '@components/v2/input/AkInputNumber';
import AkInputTextArea from '@components/v2/input/AkInputTextArea';
import GaContentView from '@components/v2/layout/GaContentView';

/* MIXINS */
import accommodationType2Const from '@mixins/const/accommodationType2Const';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import currentCompanyMixin from '@mixins/currentCompanyMixin';
import identiteContactConst from '@mixins/const/identiteContactConst';
import messageMixin from '@mixins/messageMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import prospectDisponibiliteConst from '@mixins/const/prospectDisponibiliteConst';
import prospectOfferConst from '@mixins/const/prospectOfferConst';
import prospectPriorityConst from '@mixins/const/prospectPriorityConst';
import prospectSourceConst from '@mixins/const/prospectSourceConst';
import prospectStatusConst from '@mixins/const/prospectStatusConst';
import prospectValeurConst from '@mixins/const/prospectValeurConst';
import quantityConst from '@mixins/const/quantityConst';
import randomRef from '@mixins/randomRef';
import receptionTypeConst from '@mixins/const/receptionTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import habitatTypeConst from '@mixins/const/habitatTypeConst';
import regimeJuridiqueConst from '@mixins/const/regimeJuridiqueConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import ProgressBar from 'primevue/progressbar';
import AkAddressFull from '@components/v2/input/AkAddressFull';
import receptionPersonConst from '@mixins/const/receptionPersonConst';

export default {
  components: {
    AkAddressFull,
    AkContactProspect,
    AkDropdown,
    AkFormSubmitted,
    AkInputNumber,
    AkInputTextArea,
    GaContentView,
    ProgressBar,
  },
  mixins: [
    accommodationType2Const,
    accommodationTypeConst,
    currentCompanyMixin,
    identiteContactConst,
    messageMixin,
    ownerTypesConst,
    permissionsMixin,
    prospectDisponibiliteConst,
    prospectOfferConst,
    prospectPriorityConst,
    prospectSourceConst,
    prospectStatusConst,
    prospectValeurConst,
    quantityConst,
    randomRef,
    receptionTypeConst,
    residenceTypeConst,
    habitatTypeConst,
    regimeJuridiqueConst,
    utilsMixin,
    receptionPersonConst,
  ],
  emits: ['refresh'],
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      companies: [],
      onboardings: [],
      types: [],
      onboardingAction: {},
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    // IS

    isOwnerLoaded() {
      return !!this.currentValue.id;
    },
    isPersonnePhysique() {
      return this.currentValue.ownerType === 'INDIVIDU';
    },
    isIndivision() {
      return this.currentValue.ownerType === 'INDIVISION';
    },
    isReceptionCheckinTypePhysical() {
      return this.currentValue.receptionCheckinType === 'PHYSICAL';
    },
    isReceptionCheckoutTypePhysical() {
      return this.currentValue.receptionCheckoutType === 'PHYSICAL';
    },
    isOfferOnline() {
      return this.currentValue.offer && this.currentValue.offer === 'ONLINE';
    },
    isOfferAllInclusive() {
      return this.currentValue.offer === 'ALL_INCLUSIVE';
    },

    // DISPLAY
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <GaContentView>
    <template #content>
      <AkFormSubmitted :disabled="true" reference="form">
        <div v-if="!isPersonnePhysique">
          <span class="title-border mb-4">Informations hôte</span>
          <div class="mb-3">
            <div v-for="(contact, index) in currentValue.contactList" :key="index" class="mb-4">
              <AkContactProspect
                :contact="contact"
                :index="index"
                :owner="currentValue"
                hide-for-details
                hide-validator />
            </div>
          </div>
        </div>

        <span class="title-border mb-4">Informations logement</span>
        <AkAddressFull v-model="currentValue" />
        <div class="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:gap-4 gap-2 mt-2">
          <AkDropdown
            v-model="currentValue.residenceType"
            :label="$t('prospect.residenceType')"
            :options="this.getResidenceTypeConstForProspect()" />
          <AkDropdown
            v-model="currentValue.habitatType"
            :label="$t('prospect.habitatType')"
            :options="habitatTypeConst" />
          <AkDropdown
            v-model="currentValue.regimeJuridique"
            :label="$t('prospect.regimeJuridique')"
            :options="regimeJuridiqueConst" />
          <AkDropdown
            v-model="currentValue.accommodationType"
            :label="$t('prospect.accommodationType')"
            :options="this.getAccommodationTypeConstOrder()" />
          <AkDropdown
            v-model="currentValue.accommodationType2"
            :label="$t('prospect.accommodationType2')"
            :options="this.getAccommodationType2ConstForProspect()" />
          <span class="hidden lg:block"></span>
          <AkInputNumber v-model="currentValue.area" :label="$t('prospect.area')" :min="0" suffix="m²" />
          <AkInputNumber
            v-model="currentValue.surfaceExterieur"
            :label="$t('accommodation.areaExt')"
            :min="0"
            suffix=" m²" />
          <span class="hidden lg:block"></span>
          <AkDropdown v-model="currentValue.capacity" :label="$t('prospect.capacity')" :options="quantityConst" />
          <AkDropdown v-model="currentValue.nbBedroom" :label="$t('prospect.nbBedroom')" :options="quantityConst" />
          <AkDropdown v-model="currentValue.nbBathroom" :label="$t('prospect.nbBathroom')" :options="quantityConst" />
          <AkDropdown
            v-model="currentValue.disponibilite"
            :label="$t('prospect.disponibilite')"
            :options="prospectDisponibiliteConst" />
          <AkDropdown
            v-model="currentValue.receptionCheckinType"
            :label="$t('prospect.receptionType')"
            :options="receptionTypeConst" />
          <AkDropdown
            v-if="isOfferOnline && isReceptionCheckinTypePhysical"
            v-model="currentValue.checkinPerson"
            :label="$t('prospect.checkin_person')"
            :options="receptionPersonConst" />
          <AkDropdown
            v-model="currentValue.receptionCheckoutType"
            :label="$t('prospect.receptionCheckoutType')"
            :options="receptionTypeConst" />
          <AkDropdown
            v-if="isOfferOnline && isReceptionCheckoutTypePhysical"
            v-model="currentValue.checkoutPerson"
            :label="$t('prospect.checkout_person')"
            :options="receptionPersonConst" />
        </div>
        <span class="title-border mt-4 mb-4">Informations complémentaires</span>
        <AkInputTextArea v-model="currentValue.comment" :label="$t('prospect.comment')" />
      </AkFormSubmitted>
      <div v-if="onboardings && onboardings.length">
        <h4>{{ $t('onboarding.list') }}</h4>
        <div class="form-row grid4 mb-4">
          <div v-for="item in onboardings" :key="item.id" class="accommodation-type onboarding">
            <div class="title-type">
              <router-link :to="{name: 'onboardingDetails', params: {onboardingId: item.id}}">
                <strong>Onboarding du {{ displayDateTime(item.dateCreated) }}</strong>
              </router-link>
              <i class="fa fa-trash delete" @click="showDialogDeleteOnboarding(item)" />
            </div>
            <ProgressBar :show-value="false" :value="progressValue(item.step)" />
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>
