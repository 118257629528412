import userService from '@services/userService';

export default {
  methods: {
    autocompleteUser(u) {
      return userService.autocomplete(u);
    },
    autocompleteUserById(u) {
      return userService.userDisplay(u);
    },
  },
};
