import store from '@store';
import {PermissionMap} from '@mixins/const/permissionConst';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/Login.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['loggedIn']) {
          // Redirect to the home page instead
          next({name: 'home'});
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    component: () => import('@views/ResetPwd.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/forgotPwd/:jwt',
    name: 'forgotPwd',
    component: () => import('@views/ForgetPwdV2.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/developer',
    component: () => import('@layouts/MainV2.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'reservation',
        name: 'developerReservation',
        component: () => import('@views/developer/ReservationPage.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'review',
        name: 'developerReview',
        component: () => import('@views/developer/Review.vue'),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: '/onboarding',
    component: () => import('@layouts/MainOnboarding.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ':onboardingId',
        name: 'onboardingDetails',
        component: () => import('@views/onboarding/OnboardingDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          accessTo: ['GAS', 'OWNER', 'GAV', 'COMMERCIAL'],
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@layouts/MainV2.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '403',
        name: 'unauthorized',
        component: require('@views/core/_unauthorized.vue').default,
      },
      {
        path: '404',
        name: 'notFound',
        component: require('@views/core/_404.vue').default,
      },
      {
        path: '500',
        name: 'unexpected',
        component: require('@views/core/_500.vue').default,
      },
      {
        path: 'sandbox',
        name: 'sandbox',
        component: require('@views/core/sandbox.vue').default,
      },
      {
        path: '',
        name: 'home',
        component: () => import('@views/v2/Home.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'tickets',
        name: 'tickets',
        component: () => import('@views/ticket/v2/TicketList.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'communication',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'ticket/:ticketId/details',
        name: 'ticketDetails',
        component: () => import('@views/ticket/details/TicketDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.TICKET.VIEW],
        },
      },
      {
        path: 'crg',
        name: 'crgOwner',
        component: () => import('@views/accounting/crg/CrgOwner.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'crg',
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'reservations',
        name: 'reservations',
        component: require('@views/reservation/v2/ReservationsList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operationnel',
          accessTo: ['GAS', 'GAV', 'OWNER', 'PARTENAIRE', 'HOUSEKEEPER'],
          //permissions: [PermissionMap.RESERVATION.VIEW]
        },
      },
      {
        path: 'reservation/:reservationId',
        name: 'reservationPage',
        component: () => import('@views/reservation/ReservationPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operationnel',
        },
      },
      {
        path: 'reservations/planning',
        name: 'reservationsPlanning',
        component: require('@views/reservation/planning/ReservationsPlanning.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operationnel',
          accessTo: ['GAS', 'GAV', 'OWNER', 'PARTENAIRE', 'HOUSEKEEPER'],
          //permissions: [PermissionMap.RESERVATION_PLANNING.VIEW]
        },
      },
      {
        path: 'maintenances/owner',
        name: 'maintenancesOwner',
        component: require('@views/maintenance/MaintenanceList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_hote',
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'litiges/owner',
        name: 'litigesOwner',
        component: require('@views/litige/LitigeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_hote',
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'myAccommodations',
        name: 'myAccommodations',
        component: require('@views/accommodation/v2/MyAccommodations.vue').default,
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'accommodations',
        name: 'accommodations',
        component: require('@views/accommodation/AccommodationList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId',
        name: 'accommodationPage',
        component: () => import('@views/accommodation/AccommodationPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/details',
        name: 'accommodationDetails',
        component: () => import('@views/accommodation/details/AccommodationDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/mandat/:mandatId/details',
        name: 'accommodationMandatDetails',
        component: () => import('@views/accommodation/mandat/AccommodationMandatDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/mandat/:mandatId/resiliation',
        name: 'accommodationMandatResiliation',
        component: () => import('@views/accommodation/mandat/AccommodationMandatResiliation.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/cdc/:cdcId/details',
        name: 'accommodationCdcDetails',
        component: () => import('@views/accommodation/cdc/AccommodationCdcDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/contact/:contactId/update',
        name: 'accommodationContactUpdate',
        component: () => import('@views/contacts/ContactUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
        },
      },
      {
        path: 'maintenances',
        name: 'maintenances',
        component: require('@views/maintenance/MaintenanceList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'HOUSEKEEPER', 'OWNER'],
        },
      },
      {
        path: 'maintenance/:maintenanceId/details',
        name: 'maintenanceDetails',
        component: () => import('@views/maintenance/MaintenanceDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          subMenu: 'maintenances',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'HOUSEKEEPER', 'OWNER'],
        },
      },
      {
        path: 'maintenance/:maintenanceId/update',
        name: 'maintenanceUpdate',
        component: () => import('@views/maintenance/MaintenanceUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          subMenu: 'maintenances',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'litiges',
        name: 'litiges',
        component: require('@views/litige/LitigeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          subMenu: 'litiges',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE'],
        },
      },
      {
        path: 'litige/details/:litigeId',
        name: 'litigeDetails',
        component: require('@views/litige/LitigeDetails.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          subMenu: 'litiges',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE'],
        },
      },
      {
        path: 'upsells',
        name: 'upsells',
        component: require('@views/upsell/UpsellList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          subMenu: 'upsells',
          accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'reviews',
        name: 'reviews',
        component: require('@views/review/ReviewList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'suivi_accommodations',
          subMenu: 'reviews',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'prospects',
        name: 'prospects',
        component: require('@views/prospect/ProspectList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'client',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.VIEW],
        },
      },
      {
        path: 'crm',
        name: 'crm',
        component: require('@views/crm/CrmList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'client',
          accessTo: ['GAS', 'GAV', 'COMMERCIAL'],
        },
      },
      {
        path: 'prospect/:ownerId',
        name: 'prospectPage',
        component: () => import('@views/prospect/ProspectPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'prospects',
          permissions: [PermissionMap.PROSPECT.VIEW],
        },
      },
      {
        path: 'prospect/create',
        name: 'prospectCreate',
        component: () => import('@views/prospect/ProspectCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'prospects',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.MANAGE],
        },
      },
      {
        path: 'prospect/:ownerId/update',
        name: 'prospectUpdate',
        component: () => import('@views/prospect/ProspectUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'prospects',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.MANAGE],
        },
      },
      {
        path: 'owners',
        name: 'owners',
        component: require('@views/owner/OwnerList.vue').default,
        meta: {
          authRequired: true,
          subMenu: 'client',
          permissions: [PermissionMap.OWNER.VIEW],
        },
      },
      {
        path: 'owner/:ownerId',
        name: 'ownerPage',
        component: () => import('@views/owner/OwnerPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'client',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.OWNER.VIEW],
        },
      },
      {
        path: 'owner/:ownerId/update',
        name: 'ownerUpdate',
        component: () => import('@views/owner/OwnerUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'owners',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.OWNER.MANAGE],
        },
      },
      {
        path: 'clients',
        name: 'clients',
        component: require('@views/client/ClientList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.VIEW],
        },
      },
      {
        path: 'client/:clientId',
        name: 'clientPage',
        component: () => import('@views/client/ClientPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.VIEW],
        },
      },
      {
        path: 'client/create',
        name: 'clientCreate',
        component: () => import('@views/client/ClientCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'client/:clientId/update',
        name: 'clientUpdate',
        component: () => import('@views/client/ClientUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'mandats',
        name: 'mandatPage',
        component: () => import('@views/mandat/MandatPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'client',
          subMenu: 'mandats',
          permissions: [PermissionMap.MANDAT.VIEW],
        },
      },
      {
        path: 'avenant/:avenantId/sign',
        name: 'AvenantSign',
        component: require('@views/mandat/avenant/AvenantSign.vue').default,
        meta: {
          authRequired: true,
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'employee',
        name: 'employee',
        component: require('@views/employee/EmployeeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'employee',
          //accessTo: ['GAS', 'GAV', 'ADMIN'],
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId',
        name: 'employeePage',
        component: () => import('@views/employee/EmployeePage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'employee',
          //accessTo: ['GAS', 'GAV', 'ADMIN'],
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/update',
        name: 'employeeUpdate',
        component: () => import('@views/employee/EmployeeUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'employee',
          //accessTo: ['GAS', 'GAV', 'ADMIN'],
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'housekeepers',
        name: 'housekeepers',
        component: require('@views/housekeepers/HousekeeperList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeepers',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeeper/:housekeeperId',
        name: 'housekeeperPage',
        component: require('@views/housekeepers/HousekeeperPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeepers',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeeper/create',
        name: 'housekeeperCreate',
        component: require('@views/housekeepers/HousekeeperCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeepers',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeeper/:housekeeperId/update',
        name: 'housekeeperUpdate',
        component: () => import('@views/housekeepers/HousekeeperUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeepers',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'prestataires',
        name: 'prestataires',
        component: require('@views/prestataires/PrestataireList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'prestataires',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'prestataires/:prestataireId',
        name: 'prestatairePage',
        component: require('@views/prestataires/PrestatairePage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'prestataires',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'prestataires/:prestataireId',
        name: 'prestatairePage',
        component: require('@views/prestataires/PrestatairePage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'prestataires',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'partenaires',
        name: 'partenaires',
        component: require('@views/partenaires/PartenaireList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'partenaire/:partenaireId',
        name: 'partenairePage',
        component: require('@views/partenaires/PartenairePage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'partenaire/create',
        name: 'partenaireCreate',
        component: require('@views/partenaires/PartenaireCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'partenaire/:partenaireId/update',
        name: 'partenaireUpdate',
        component: () => import('@views/partenaires/PartenaireUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'commercials',
        name: 'commercials',
        component: require('@views/commercials/CommercialList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'commercials',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'commercial/:commercialId',
        name: 'commercialPage',
        component: require('@views/commercials/CommercialPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'commercials',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'commercial/create',
        name: 'commercialCreate',
        component: require('@views/commercials/CommercialCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'commercials',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'commercial/:commercialId/update',
        name: 'commercialUpdate',
        component: require('@views/commercials/CommercialUpdate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'commercials',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },

      // Tasks Management (gestion_tache)

      {
        path: 'mission-orders',
        name: 'mission-orders',
        component: require('@views/operationnel/missionorders/MissionOrderList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'mission-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'mission-order/:missionOrderId',
        name: 'mission-order-detail',
        component: require('@views/operationnel/missionorders/MissionOrderPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'mission-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'waiting-orders',
        name: 'waiting-orders',
        component: require('@views/operationnel/waitingorders/MissionWaitingOrdersList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'waiting-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'suivimissions',
        name: 'suiviMissions',
        component: require('@views/operationnel/missionsuivi/MissionSuivi.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'suiviMissions',
          accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'operationnel/mission/planning/intervenants',
        name: 'missionPlanningParticipant',
        component: require('@views/operationnel/mission/MissionPlanningParticipant.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'planningEmployees',
          accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'operationnel/mission/planning/accommodation',
        name: 'accommodationPlanning',
        component: require('@views/operationnel/accommodationplanning/AccommodationPlanning.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'accommodationPlanning',
          accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'mission-dashboard',
        name: 'mission-dashboard',
        component: require('@views/operationnel/missiondashboard/MissionDashboard.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'mission-dashboard',
          accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'orders/create/:accommodationId',
        name: 'ordersCreate',
        component: require('@views/missionordercreate/MissionOrderCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache',
          subMenu: 'waiting-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },

      {
        path: 'suivi-missions-partner',
        name: 'suiviMissionsPartner',
        component: require('@views/operationnel/missionsuivi/MissionSuiviPartner.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'gestion_tache_partner',
          subMenu: 'suiviMissionsPartner',
          accessTo: ['PARTENAIRE'],
        },
      },

      {
        path: 'accounting',
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: 'payment',
            name: 'reservationsPayment',
            component: () => import('@views/accounting/payment/PaymentV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
            },
          },
          {
            path: 'paymentdetails/:reservationId',
            name: 'reservationsPaymentDetails',
            component: () => import('@views/accounting/payment/detail/PaymentDetailsV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
            },
          },
          {
            path: 'cancel',
            name: 'reservationsCancel',
            component: () => import('@views/accounting/cancel/CancelV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
            },
          },
          {
            path: 'cancel/details/:reservationId',
            name: 'cancelDetails',
            component: () => import('@views/accounting/cancel/detail/CancelDetailsV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'accommodations',
              permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
            },
          },
          {
            path: 'crg',
            name: 'crgPage',
            component: () => import('@views/accounting/crg/CrgPage.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              permissions: [PermissionMap.CRG.VIEW, PermissionMap.CRG_CONSULTATION.VIEW],
            },
          },
          {
            path: 'crg/:crgId',
            name: 'crgView',
            component: () => import('@views/accounting/crg/CrgViewV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'accounting',
              permissions: [PermissionMap.CRG_TRANSFERT.VIEW, PermissionMap.CRG_CONSULTATION.VIEW],
            },
          },
          {
            path: 'crgPayment/:paymentId',
            name: 'crgPayment',
            component: () => import('@views/accounting/crg/CrgPaymentViewV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'accounting',
              permissions: [PermissionMap.CRG_TRANSFERT.VIEW],
            },
          },
          {
            path: 'crgHistory/:crgId',
            name: 'crgHistoryView',
            component: () => import('@views/accounting/crg/CrgHistoryViewV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'accounting',
              permissions: [PermissionMap.CRG_TRANSFERT.VIEW, PermissionMap.CRG_CONSULTATION.VIEW],
            },
          },
          {
            path: 'stayrate',
            name: 'accountingStayRate',
            component: require('@views/accounting/stayrates/StayRateList.vue').default,
            meta: {
              menuSelected: 'compta',
              permissions: [PermissionMap.TDS_GROUP.VIEW],
            },
          },
          {
            path: 'stayratedetails/:reconciliationId/:groupLabel',
            name: 'accountingStayRateDetails',
            component: () => import('@views/accounting/stayrates/StayRateDetails.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'accounting',
              permissions: [PermissionMap.TDS_TRANSFERT.VIEW],
            },
          },
          {
            path: 'visitortax',
            name: 'accountingVisitorTax',
            component: require('@views/accounting/visitortax/StayRatePage.vue').default,
            meta: {
              menuSelected: 'accounting',
              permissions: [PermissionMap.TDS.VIEW],
            },
          },
          {
            path: 'ownercancel',
            name: 'accountingOwnerCancel',
            component: () => import('@views/accounting/ownercancel/OwnerCancel.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              accessTo: ['GAS', 'GAV'],
            },
          },
          {
            path: 'facturationhote2',
            name: 'accountingFacturationHote',
            component: () => import('@views/accounting/facturationhote2/FacturationHote.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              permissions: [PermissionMap.FACTURATION_HOTE.VIEW],
            },
          },
          {
            path: 'facturationvoyageur',
            name: 'accountingFacturationVoyageur',
            component: () => import('@views/accounting/facturationvoyageur/FacturationVoyageur.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'compta',
              permissions: [PermissionMap.FACTURATION_VOYAGEUR.VIEW],
            },
          },
          {
            path: 'freeod',
            name: 'accountingFreeOd',
            component: require('@views/accounting/FreeOdV2.vue').default,
            meta: {
              menuSelected: 'accounting',
              permissions: [PermissionMap.OD_LIBRE.MANAGE],
            },
          },
          {
            path: 'bankReconciliation',
            name: 'accountingComptaRapprochementList',
            component: () => import('@views/accounting/bankreconciliation/ComptaRapprochementListV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'accounting',
              permissions: [PermissionMap.BANKING_RECONCILIATION.VIEW],
            },
          },
          {
            path: 'recapitulatiffacturation',
            name: 'accountingRecapitulatifFacturation',
            component: () => import('@views/accounting/recap/RecapitulatifV2.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'rapport',
              subMenu: 'accountingRecapitulatifFacturation',
              accessTo: ['GAS', 'GAV'],
              // TODO: inverser les lignes pour le CDC Concierge V2 partie compta
              // accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
            },
          },
          {
            path: 'transactions',
            name: 'accountingTransactions',
            component: require('@views/accounting/transactions/Transactions.vue').default,
            meta: {
              menuSelected: 'rapport',
              subMenu: 'accountingTransactions',
              permissions: [PermissionMap.TRANSACTION.VIEW],
            },
          },
          {
            path: 'transaction/:transactionId',
            name: 'accountingTransactionUpdate',
            component: require('@views/accounting/transactions/TransactionUpdate.vue').default,
            meta: {
              menuSelected: 'rapport',
              subMenu: 'accountingTransactions',
              permissions: [PermissionMap.TRANSACTION.MANAGE],
            },
          },
        ],
      },

      {
        path: 'rapport',
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: 'thirdpartyaccount',
            name: 'accountingThirdPartyAccount',
            component: require('@views/accounting/thirdpartyaccount/ThirdPartyAccount.vue').default,
            meta: {
              menuSelected: 'rapport',
              permissions: [PermissionMap.THIRD_ACCOUNT.VIEW],
            },
          },
          {
            path: 'thirdpartydetails/:accountId',
            name: 'accountingThirdPartyDetails',
            component: () => import('@views/accounting/thirdpartyaccount/ThirdPartyDetails.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'rapport',
              subMenu: 'accountingThirdPartyAccount',
              permissions: [PermissionMap.THIRD_ACCOUNT.VIEW],
            },
          },
        ],
      },
      {
        path: 'admin',
        meta: {
          authRequired: true,
          accessTo: ['ADMIN'],
        },
        children: [
          {
            path: 'societeGuestadom',
            name: 'settingsSocieteGuestadom',
            component: require('@views/settings/SocieteGuestadom.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_general',
            },
          },
          {
            path: 'companies',
            name: 'companies',
            component: () => import('@views/company/CompanyList.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'admin_general',
            },
          },
          {
            path: 'company',
            meta: {
              authRequired: true,
              menuSelected: 'companies',
            },
            children: [
              {
                path: ':companyId',
                name: 'companyPage',
                component: () => import('@views/company/CompanyPage.vue'),
                meta: {
                  authRequired: true,
                  subMenu: 'companies',
                  menuSelected: 'admin_general',
                },
              },
              {
                path: ':companyId/update',
                name: 'companyUpdate',
                component: () => import('@views/company/CompanyUpdate.vue'),
                meta: {
                  authRequired: true,
                  subMenu: 'companies',
                  menuSelected: 'admin_general',
                },
              },
              {
                path: 'create',
                name: 'companyCreate',
                component: () => import('@views/company/CompanyCreate.vue'),
                meta: {
                  authRequired: true,
                  subMenu: 'companies',
                  menuSelected: 'admin_general',
                },
              },
            ],
          },
          {
            path: 'documentmodel',
            name: 'settingsDocumentModel',
            component: require('@views/documentmodel/DocumentModelList.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_general',
            },
          },
          {
            path: 'ticketcategory',
            name: 'settingsTicketCategory',
            component: require('@views/settings/TicketCategoryList.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_general',
            },
          },
          {
            path: 'pole',
            name: 'settingsPole',
            component: require('@views/pole/PoleList.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_rh',
            },
          },
          {
            path: 'stakeholder',
            name: 'settingsStakeholder',
            component: require('@views/settings/Stakeholder/StakeholderPage.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_rh',
            },
          },
          {
            path: 'contract',
            name: 'settingsContract',
            component: require('@views/settings/ContractsList.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_rh',
            },
          },
          {
            path: 'epitype',
            name: 'settingsEpiType',
            component: require('@views/settings/EpiTypeList.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_rh',
            },
          },
          {
            path: 'geosector',
            name: 'settingsGeoSector',
            component: require('@views/geosector/GeoSectorList.vue').default,
            meta: {
              accessTo: ['ADMIN'],
              menuSelected: 'admin_rh',
            },
          },
          {
            path: 'achatHote',
            name: 'settingsAchatHoteType',
            component: require('@views/settings/AchatHoteTypeList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_client',
            },
          },
          {
            path: 'raisonAchatHote',
            name: 'settingsRaisonAchatHote',
            component: require('@views/settings/RaisonAchatHoteList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_client',
            },
          },
          {
            path: 'motifDeplacement',
            name: 'settingsMotifDeplacement',
            component: require('@views/settings/MotifDeplacementList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_client',
            },
          },
          {
            path: 'profileClient',
            name: 'settingsProfileClient',
            component: require('@views/settings/ProfileClientList.vue').default,
            meta: {
              authRequired: true,
              menuSelected: 'admin_client',
              accessTo: ['ADMIN'],
            },
          },
          {
            path: 'formeJuridique',
            name: 'settingsFormeJuridique',
            component: require('@views/settings/FormeJuridiqueList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_client',
            },
          },
          {
            path: 'infosMandat',
            name: 'settingsInfosMandat',
            component: require('@views/settings/InfosMandat.vue').default,
            meta: {
              authRequired: true,
              menuSelected: 'admin_mandat',
              accessTo: ['ADMIN'],
            },
          },
          {
            path: 'mandats',
            name: 'settingsMandat',
            component: () => import('@views/settings/MandatRegister.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'admin_mandat',
              accessTo: ['ADMIN'],
            },
          },
          {
            path: 'mandatattachedfiles',
            name: 'settingsMandatAttachedFile',
            component: () => import('@views/settings/MandatAttachedFiles.vue'),
            meta: {
              authRequired: true,
              menuSelected: 'admin_mandat',
              accessTo: ['ADMIN'],
            },
          },
          {
            path: 'resiliationreason',
            name: 'settingsResiliationReason',
            component: require('@views/settings/ResiliationReasonList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_mandat',
            },
          },
          {
            path: 'parametrageCommission',
            name: 'settingsParametrageCommission',
            component: require('@views/settings/ParametrageCommission/ParametrageCommissionPage.vue').default,
            meta: {
              authRequired: true,
              menuSelected: 'admin_mandat',
              accessTo: ['ADMIN'],
            },
          },
          {
            path: 'cdcmodels',
            name: 'settingsCdcmodel',
            component: require('@views/cdcmodel/CdcmodelList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],

              menuSelected: 'admin_accommodation',
            },
          },
          {
            path: 'cdcmodels/:cdcModelId/details',
            name: 'settingsCdcDetails',
            component: () => import('@views/cdcmodel/CdcmodelDetails.vue'),
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_accommodation',
            },
          },
          {
            path: 'zone',
            name: 'settingsZone',
            component: require('@views/settings/ZoneList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_accommodation',
            },
          },
          {
            path: 'contacttypes',
            name: 'settingsContactType',
            component: require('@views/settings/ContactTypeList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_accommodation',
            },
          },
          {
            path: 'task',
            name: 'settingsTask',
            component: require('@views/task/TaskList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_accommodation',
            },
          },
          {
            path: 'extraType',
            name: 'settingsExtraType',
            component: require('@views/settings/ExtraTypeList.vue').default,
            meta: {
              authRequired: true,
              menuSelected: 'admin_accommodation',
              accessTo: ['ADMIN'],
            },
          },
          {
            path: 'cancelrule',
            name: 'settingsCancelRule',
            component: require('@views/settings/CancelRuleList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              subMenu: 'admin_rm',
            },
          },
          {
            path: 'mandatclusterrm',
            name: 'settingsMandatClusterRm',
            component: require('@views/settings/MandatClusterRMList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              subMenu: 'admin_rm',
            },
          },
          {
            path: 'marche',
            name: 'settingsMarche',
            component: require('@views/settings/MarcheList.vue').default,
            meta: {
              authRequired: true,
              accessTo: ['ADMIN'],
              menuSelected: 'admin_rm',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: () => import('@layouts/Main.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'old',
        name: 'homeOld',
        component: () => import('@views/Home.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@views/user/Profile.vue'),
        meta: {
          authRequired: true,
          //accessTo: ["GAV", "GAS", "OWNER"],
          restrictedTo: ['ADMIN'],
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@views/user/UserList.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'user/create',
        name: 'userCreate',
        component: () => import('@views/user/UserCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'users',
        },
      },
      {
        path: 'user/:userId/update',
        name: 'userUpdate',
        component: () => import('@views/user/UserUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'users',
        },
      },
      {
        path: 'company/:companyId/accommodationtypes/create',
        name: 'companyAccommodationTypeCreate',
        component: () => import('@views/company/accommodationtype/CompanyAccommodationTypeCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'companys',
        },
      },

      {
        path: 'client/:clientId/reservation/update/:reservationId',
        name: 'clientReservationUpdate',
        component: () => import('@views/client/reservations/ClientReservationUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'client/:clientId/reservation/details/:reservationId',
        name: 'clientReservationDetails',
        component: () => import('@views/client/reservations/ClientReservationDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.VIEW],
        },
      },

      {
        path: 'suivis/:onboardingId',
        name: 'suivisWithId',
        component: () => import('@views/onboarding/OnboardingSuivi.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivis',
          accessTo: ['GAS'],
        },
      },
      {
        path: 'employee/:employeeId/contract/create',
        name: 'employeeContractCreate',
        component: () => import('@views/employee/contract/EmployeeContractCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/contract/:employeeContractId/update',
        name: 'employeeContractUpdate',
        component: () => import('@views/employee/contract/EmployeeContractUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/contract/:employeeContractId/details',
        name: 'employeeContractDetails',
        component: () => import('@views/employee/contract/EmployeeContractDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId/contract/:employeeContractId/amendment',
        name: 'employeeAmendmentCreate',
        component: () => import('@views/employee/amendment/EmployeeAmendmentCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/restriction/create',
        name: 'restrictionCreate',
        component: () => import('@views/employee/restriction/EmployeeRestrictionCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/restriction/:restrictionId/update',
        name: 'restrictionUpdate',
        component: () => import('@views/employee/restriction/EmployeeRestrictionUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/restriction/:restrictionId/details',
        name: 'restrictionDetails',
        component: () => import('@views/employee/restriction/EmployeeRestrictionDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId/epi/create',
        name: 'epiCreate',
        component: () => import('@views/employee/epi/EmployeeEpiCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/epi/:epiId/update',
        name: 'epiUpdate',
        component: () => import('@views/employee/epi/EmployeeEpiUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'old/reservations',
        name: 'reservationsOld',
        component: require('@views/reservation/ReservationsList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'reservation',
          accessTo: ['GAS', 'GAV', 'OWNER', 'PARTENAIRE', 'HOUSEKEEPER'],
          //permissions: [PermissionMap.RESERVATION.VIEW]
        },
      },

      {
        path: 'reservations/defautpaiement',
        name: 'defautpaiement',
        component: require('@views/reservation/DefautPaiement.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'reservation',
          permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
        },
      },
      // ACCOMMODATIONS
      {
        path: 'accommodation/:accommodationId/purchase/create',
        name: 'accommodationPurchaseCreate',
        component: () => import('@views/accommodation/ownerPurchase/AccommodationOwnerPurchaseCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/purchase/details/:ownerPurchaseId',
        name: 'accommodationOwnerPurchaseDetails',
        component: () => import('@views/accommodation/ownerPurchase/AccommodationOwnerPurchaseDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/purchase/update/:ownerPurchaseId',
        name: 'accommodationPurchaseUpdate',
        component: () => import('@views/accommodation/ownerPurchase/AccommodationOwnerPurchaseUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/reservation/update/:reservationId',
        name: 'accommodationReservationUpdate',
        component: () => import('@views/accommodation/reservation/AccommodationReservationUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/reservation/details/:reservationId',
        name: 'accommodationReservationPage',
        component: () => import('@views/accommodation/reservation/AccommodationReservationPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      //            {
      //                path: 'ownerPurchases',
      //                name: 'ownerPurchases',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseList.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                },
      //            },
      //            {
      //                path: 'ownerPurchase/create',
      //                name: 'ownerPurchaseCreate',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseCreate.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                    subMenu: "ownerPurchases",
      //                },
      //            },
      //            {
      //                path: 'ownerPurchase/details/:ownerPurchaseId',
      //                name: 'ownerPurchaseDetails',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseDetails.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                    subMenu: "ownerPurchases",
      //                },
      //            },
      //            {
      //                path: 'ownerPurchase/update/:ownerPurchaseId',
      //                name: 'ownerPurchaseUpdate',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseUpdate.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                    subMenu: "ownerPurchases",
      //                },
      //            },
      {
        path: 'settings/purchaseType',
        name: 'settingsPurchaseType',
        component: require('@views/settings/PurchaseTypeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },

      {
        path: 'settings/originMarketing',
        name: 'settingsOriginMarketing',
        component: require('@views/settings/OrigineMarketingList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },

      {
        path: 'settings/stock',
        name: 'settingsStock',
        component: require('@views/settings/StockList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      /**
             {
             path: 'settings/jobs',
             name: 'settingsJobs',
             component: require('@views/settings/JobsList.vue').default,
             meta: {
             menuSelected: "settings",
             accessTo: ['ADMIN'],
             },
             },
             **/

      {
        path: 'settings/filetype',
        name: 'settingsFileType',
        component: require('@views/settings/FileTypeList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'admin/mandat',
        name: 'mandatAdminPage',
        component: require('@views/mandat/MandatAdminPage.vue').default,
        meta: {
          menuSelected: 'mandat',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/motifrefus',
        name: 'settingsMotifRefus',
        component: require('@views/motifrefus/MotifRefusList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },

      {
        path: 'settings/sendcrg',
        name: 'sendCrg',
        component: require('@views/settings/SendCRG.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/amountThreshold',
        name: 'amountThreshold',
        component: require('@views/settings/AmountThreshold.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'task/:taskId/details',
        name: 'taskDetails',
        component: () => import('@views/task/TaskDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          subMenu: 'settingsTask',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'cdcmodel/:cdcmodelId/details',
        name: 'cdcmodelDetails',
        component: () => import('@views/cdcmodel/CdcmodelDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          subMenu: 'settingsCdcmodel',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'taskgroup/:taskgroupId/details',
        name: 'taskgroupDetails',
        component: () => import('@views/taskgroup/TaskgroupDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId',
        name: 'stockPage',
        component: () => import('@views/stock/StockPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId/products/create',
        name: 'stockProductCreate',
        component: () => import('@views/stock/product/StockProductCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId/product/:productId/details',
        name: 'stockProductDetails',
        component: () => import('@views/stock/product/StockProductDetails.vue'),
        meta: {
          authRequired: true,
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId/product/:productId/update',
        name: 'stockProductUpdate',
        component: () => import('@views/stock/product/StockProductUpdate.vue'),
        meta: {
          authRequired: true,
          accessTo: ['ADMIN'],
        },
      },

      {
        path: 'ticket/create',
        name: 'ticketCreate',
        component: () => import('@views/ticket/create/TicketCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.TICKET.MANAGE],
        },
      },
      {
        path: 'ticket/:ticketId/update',
        name: 'ticketUpdate',
        component: () => import('@views/ticket/update/TicketUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.TICKET.MANAGE],
        },
      },
      {
        path: 'accounting/balance',
        name: 'accountingBalance',
        component: require('@views/accounting/Balance.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.BALANCE.VIEW],
        },
      },
      {
        path: 'accounting/account',
        name: 'accountingAccount',
        component: require('@views/accounting/Account.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.CONSULTATION.VIEW],
        },
      },
      {
        path: 'accounting/bankReconciliation/:rapprochementId',
        name: 'accountingComptaRapprochement',
        component: () => import('@views/accounting/bankreconciliation/ComptaRapprochement.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.BANKING_RECONCILIATION.MANAGE],
        },
      },
      {
        path: 'accounting/bankReconciliation/details/:rapprochementId',
        name: 'accountingComptaRapprochementDetails',
        component: () => import('@views/accounting/bankreconciliation/ComptaRapprochementDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.BANKING_RECONCILIATION.VIEW],
        },
      },
      {
        path: 'accounting/ownercancel/:reservationId',
        name: 'accountingOwnerCancelDetails',
        component: () => import('@views/accounting/ownercancel/OwnerCancelDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          accessTo: ['GAS', 'GAV'],
        },
      },

      {
        path: 'contact',
        name: 'contact',
        component: require('@views/mescontacts/MesContacts.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'contact',
        },
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    component: require('@views/core/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '404',
  },
];
