export default {
  data() {
    return {
      activityInterlocutorTypeConst: [
        {label: this.$t('activity.reservation'), value: 'RESERVATION'},
        {label: this.$t('activity.accommodation'), value: 'ACCOMMODATION'},
        {label: this.$t('activity.proprietaire'), value: 'PROPRIETAIRE'},
        {label: this.$t('activity.prospect'), value: 'PROSPECT'},
        {label: this.$t('activity.voyageur'), value: 'VOYAGEUR'},
        {label: this.$t('activity.salarie'), value: 'SALARIE'},
        {label: this.$t('undefined'), value: undefined},
      ],
      activityInterlocutorTypeForHousekeeperConst: [
        {label: this.$t('activity.reservation'), value: 'RESERVATION'},
        {label: this.$t('activity.accommodation'), value: 'ACCOMMODATION'},
        {label: this.$t('activity.proprietaire'), value: 'PROPRIETAIRE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForactivityInterlocutorTypeConst(value) {
      let result = this.activityInterlocutorTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
