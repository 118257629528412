export default {
  data() {
    return {
      companyFormConst: [
        {label: 'SARL', value: 'SARL'},
        {label: 'SCI', value: 'SCI'},
        {label: 'SAS', value: 'SAS'},
        {label: this.$t('undefined'), value: undefined},
      ],
      companyFormConstForProspect: [
        {label: 'SARL', value: 'SARL'},
        {label: 'SCI', value: 'SCI'},
        {label: 'SAS', value: 'SAS'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForCompanyFormConst(value) {
      let result = this.companyFormConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getCompanyFormConstForProspect() {
      return this.companyFormConstForProspect;
    },
  },
};
