<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkFilesUpload from '@components/v2/input/AkFilesUpload.vue';
import AkInputEditor from '@components/v2/input/AkInputEditor.vue';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import activityContactWayConst from '@mixins/const/activityContactWayConst';
import activityInterlocutorTypeConst from '@mixins/const/activityInterlocutorTypeConst';
import activityMotifConst from '@mixins/const/activityMotifConst';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import employeeAutocompleteMixin from '@mixins/autocomplete/employeeAutocompleteMixin';
import userAutocompleteMixin from '@mixins/autocomplete/userAutocompleteMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import activityService from '@services/activityService';
import ficService from '@services/ficService';
import AkFicItem from '@components/v2/layout/AkFicItem.vue';
import AkLabel from '@components/v2/general/AkLabel.vue';

export default {
  components: {
    AkButtonFooterSidebar,
    AkLabel,
    AkFicItem,
    AkInputEditor,
    AkAutocomplete,
    AkCalendar,
    AkSidebar,
    AkDropdown,
    AkFilesUpload,
  },
  mixins: [
    activityContactWayConst,
    activityInterlocutorTypeConst,
    activityMotifConst,
    utilsMixin,
    permissionsMixin,
    employeeAutocompleteMixin,
    userAutocompleteMixin,
    messageMixin,
  ],
  emits: ['update', 'updateFile', 'hide'],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      activityId: undefined,
      activity: undefined,
      files: [],
      updateSidebarVisible: false,
      submitted: false,
      loading: true,
    };
  },
  validations() {
    return {
      activity: {
        date: {required},
        contactWay: {required},
        motif: {required},
        comment: {required},
      },
    };
  },
  methods: {
    show(id) {
      this.activityId = id;
      this.reload();
      this.updateSidebarVisible = true;
    },
    hide() {
      this.updateSidebarVisible = false;
      this.activity = undefined;
      this.files = [];
      this.$emit('hide');
    },
    reload() {
      this.loading = true;

      Promise.all([
        activityService.activity(this.activityId),
        ficService.fics({tableName: 'activity', tableId: this.activityId}),
      ]).then(([activity, files]) => {
        this.activity = activity;
        this.activity.files = files;
        this.loading = false;
      });
    },
    update() {
      this.submitted = true;

      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      if (this.files?.length > 0) {
        ficService.create('activity', this.activityId, this.files).then(() => {});
      }
      activityService
        .update(this.activity)
        .then(() => {
          this.$emit('update');
        })
        .catch(() => {
          this.addError(this.$t('unknow_error'));
        })
        .finally(() => {
          this.submitted = false;
          this.hide();
        });
    },
    deleteFile(file) {
      ficService
        .delete(file)
        .then(() => {
          this.activity.files = this.activity.files.filter((f) => f.id !== file.id);
          this.$emit('updateFile');
          this.success('Le fichier a bien été supprimé');
        })
        .catch((e) => {
          this.addError('Une erreur est survenue');
        });
    },
  },
};
</script>

<template>
  <AkSidebar
    v-model:visible="updateSidebarVisible"
    :baseZIndex="9998"
    :dismissable="true"
    block-scroll
    position="right"
    @hide="hide">
    <template #container>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('activity.update') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar v-if="!loading" class="flex flex-col gap-4 md:gap-2">
          <div class="grid grid-cols-1 gap-4 md:gap-2">
            <AkCalendar
              v-model="activity.date"
              :label="$t('activity.dateHour')"
              :submitted="submitted"
              :validator="v$.activity.date"
              show-time />
            <AkAutocomplete
              v-if="this.isGasGav()"
              v-model="activity.who"
              :init-method="autocompleteUserById"
              :label="$t('activity.who')"
              :search-method="autocompleteUser"
              :search-object="{enabled: true}"
              :submitted="submitted"
              option-label="display"
              option-value="id" />
            <AkDropdown
              v-model="activity.contactWay"
              :label="$t('activity.contactWay')"
              :options="activityContactWayConst"
              :submitted="submitted"
              :validator="v$.activity.contactWay" />
            <AkDropdown
              v-model="activity.motif"
              :label="$t('activity.motif')"
              :options="activityMotifConst"
              :submitted="submitted"
              :validator="v$.activity.motif" />
            <AkInputEditor
              v-model="activity.comment"
              :label="$t('activity.comment')"
              :rows="13"
              :submitted="submitted"
              :validator="v$.activity.comment"
              style="white-space: pre" />
          </div>

          <AkLabel>{{ $t('activity.files') }}</AkLabel>

          <div v-if="activity.files && activity.files.length > 0" class="grid grid-cols-1 mt-4 gap-4 md:gap-2">
            <div v-for="(file, idx) of activity.files" :key="file" class="flex flex-col gap-2">
              <AkFicItem v-model="activity.files[idx]" @delete="deleteFile(file)" />
            </div>
          </div>
          <AkFilesUpload v-model="files" :submitted="submitted" multiple />

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancel-label="$t('cancel')"
            :validate-label="$t('update')"
            @action="update()"
            @cancel="hide()" />
        </perfect-scrollbar>
        <div v-else class="flex flex-col items-center gap-2 pt-4">
          <span class="spinner primary xl" />
          <span class="text-gray font-normal font-inter">Récuperation en cours...</span>
        </div>
      </div>
    </template>
  </AkSidebar>
</template>
