export default {
  data() {
    return {
      receptionPersonConst: [
        {label: this.$t('accommodation.ownerName'), value: 'OWNER'},
        {label: this.$t('prestataire.prestataire'), value: 'PRESTATAIRE'},
      ],
    };
  },
  methods: {
    displayForReceptionPersonConst(value) {
      let result = this.receptionPersonConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
