export default {
  data() {
    return {
      prospectValeurConst: [
        {label: 'Je ne sais pas', value: 'Je ne sais pas'},
        {label: '1 point', value: '1 point'},
        {label: '2 points', value: '2 points'},
        {label: '3 points', value: '3 points'},
        {label: '4 points', value: '4 points'},
        {label: '5 points', value: '5 points'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForProspectValeurConst(value) {
      let result = this.prospectValeurConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
