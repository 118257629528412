<script>
import useVuelidate from '@vuelidate/core';
import {email, helpers, required, requiredIf} from '@vuelidate/validators';
import {isValidPhoneNumber} from 'libphonenumber-js';

/* COMPOSANTS */
import {Icon} from '@iconify/vue';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkInputText from '@components/v2/input/AkInputText';

/* MIXINS */
import civilitiesConst from '@mixins/const/civilitiesConst';
import companyFormConst from '@mixins/const/companyFormConst';
import AkAddressFull from '@components/v2/input/AkAddressFull';
import itemLabelService from '@services/itemLabelService';
import permissionsMixin from '@mixins/permissionsMixin';
import formatMixin from '@mixins/formatMixin';
import AkDialog from '@components/general/AkDialog.vue';

const phoneValidator = (value) => !value || isValidPhoneNumber(value);

export default {
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  components: {
    Icon,
    AkDialog,
    AkAddressFull,
    AkDropdown,
    AkInputMoney,
    AkInputText,
  },
  mixins: [civilitiesConst, companyFormConst, formatMixin, permissionsMixin, formatMixin],
  props: {
    owner: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideValidator: {
      type: Boolean,
      required: false,
      default: false,
    },
    fromOnboarding: {
      type: Boolean,
      required: false,
      default: false,
    },
    fromCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideForDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['delete'],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      listFormeJuridique: [],
    };
  },
  validations() {
    return {
      contact: {
        civility: {required: requiredIf(!this.hideValidator)},
        firstName: {required: requiredIf(!this.hideValidator)},
        lastName: {required: requiredIf(!this.hideValidator)},
        companyName: {required: requiredIf(this.isPersonneMorale && !this.hideValidator)},
        formeJuridiqueId: {required: requiredIf(this.isPersonneMorale && !this.hideValidator)},
        raisonSociale: {required: requiredIf(this.isPersonneMorale && !this.hideValidator)},
        email: {email, required: requiredIf(!this.hideValidator)},
        phone: {
          required: requiredIf(!this.hideValidator),
          phoneValidator: helpers.withMessage(
            'Vérifier le format du numéro de téléphone (Doit commencer par +33)',
            phoneValidator,
          ),
        },
        address: {required: requiredIf(this.isFromOnboarding && !this.hideValidator)},
        postalCode: {required: requiredIf(this.isFromOnboarding && !this.hideValidator)},
        city: {required: requiredIf(this.isFromOnboarding && !this.hideValidator)},
        state: {required: requiredIf(this.isFromOnboarding && !this.hideValidator)},
        country: {required: requiredIf(this.isFromOnboarding && !this.hideValidator)},
      },
    };
  },
  mounted() {
    let p2 = itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'});
    p2.then((data) => {
      this.listFormeJuridique = data;
    });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.contact;
      },
    },
    canEditMail() {
      return this.currentValue.id !== undefined;
    },
    isIndividu() {
      return this.owner.ownerType === 'INDIVIDU';
    },
    isIndivision() {
      return this.owner.ownerType === 'INDIVISION';
    },
    isPersonneMorale() {
      return this.owner.ownerType === 'PERSONNE_MORALE';
    },
    isFromOnboarding() {
      return this.fromOnboarding;
    },
    isLoadingFormeJuridique() {
      return this.listFormeJuridique === undefined;
    },
    hideField() {
      return this.hideForDetails && !this.fromCreate;
    },
  },
  methods: {
    confirmDelete() {
      this.$refs.dialogDelete.show();
    },
    performDelete() {
      this.$refs.dialogDelete.hide();
      this.$emit('delete', this.index);
    },
  },
};
</script>

<template>
  <span v-if="isIndivision" class="title-border text-dark text-md font-bold mb-3">
    Indivisaire N°{{ index + 1 }}
    <i
      v-if="canDelete && pDisabled !== true"
      class="ga-icon ga-trash text-2xl text-red ml-2 cursor-pointer"
      @click="confirmDelete" />
  </span>
  <div v-if="isIndividu || isIndivision" class="flex flex-col md:gap-4 gap-2">
    <div class="grid grid-cols-1 lg:grid-cols-3 md:gap-4 gap-2">
      <AkDropdown
        v-model="currentValue.civility"
        :label="$t('contact.civility')"
        :options="civilitiesConst"
        :validator="v$.contact.civility" />
      <AkInputText
        v-model="currentValue.lastName"
        :formatter="toUppercase"
        :label="$t('contact.lastName')"
        :validator="v$.contact.lastName" />
      <AkInputText
        v-model="currentValue.firstName"
        :label="$t('contact.firstName')"
        :validator="v$.contact.firstName" />
      <AkInputText
        v-model="currentValue.email"
        :disabled="canEditMail"
        class="lg:col-span-2"
        :label="$t('contact.email')"
        :validator="v$.contact.email"
        icon="ga-icon ga-email" />
      <AkInputText
        v-model="currentValue.phone"
        :label="$t('contact.phone')"
        :validator="v$.contact.phone"
        icon="ga-icon ga-call" />
    </div>

    <AkAddressFull
      label="Adresse principale"
      v-model="currentValue"
      :always-display-map="false"
      :can-change-position="false"
      :from-onboarding="fromOnboarding"
      :validator="v$.contact" />
  </div>
  <div v-if="isPersonneMorale" class="flex flex-col md:gap-4 gap-2">
    <div class="grid grid-cols-1 lg:grid-cols-2 md:gap-4 gap-2">
      <AkDropdown
        v-model="currentValue.formeJuridiqueId"
        :label="$t('contact.formeJuridique')"
        :loading="isLoadingFormeJuridique"
        :options="listFormeJuridique"
        :validator="v$.contact.formeJuridiqueId"
        option-label="label"
        option-value="id" />
      <AkInputText
        v-model="currentValue.raisonSociale"
        :formatter="toUppercase"
        :label="$t('contact.raisonSociale')"
        :validator="v$.contact.raisonSociale" />
      <AkInputText
        v-model="currentValue.companyName"
        :label="$t('contact.companyName')"
        :validator="v$.contact.companyName" />
      <AkInputMoney v-model="currentValue.capital" label="Capital (€)" />
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-3 md:gap-4 gap-2">
      <AkDropdown
        v-model="currentValue.civility"
        :label="$t('contact.civilityManager')"
        :options="civilitiesConstForProspect"
        :validator="v$.contact.civility" />
      <AkInputText
        v-model="currentValue.lastName"
        :formatter="toUppercase"
        :label="$t('contact.lastNameManager')"
        :validator="v$.contact.lastName" />
      <AkInputText
        v-model="currentValue.firstName"
        :label="$t('contact.firstNameManager')"
        :validator="v$.contact.firstName" />
      <AkInputText
        v-model="currentValue.function"
        :label="$t(isPersonneMorale ? 'contact.functionManager' : 'contact.function')" />
      <AkInputText
        v-if="!this.hideField"
        v-model="currentValue.email"
        :disabled="canEditMail"
        :label="$t('contact.email')"
        :validator="v$.contact.email"
        icon="ga-icon ga-email" />
      <AkInputText
        v-if="!this.hideField"
        v-model="currentValue.phone"
        :label="$t('contact.phone')"
        :validator="v$.contact.phone"
        icon="ga-icon ga-call" />
    </div>
    <AkAddressFull
      v-if="!this.hideField"
      v-model="currentValue"
      :always-display-map="false"
      :can-change-position="false"
      :from-onboarding="fromOnboarding"
      :label="$t('contact.address1Manager')"
      :validator="v$.contact" />

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 md:gap-4 gap-2">
      <AkInputText v-model="currentValue.villeRcs" :formatter="toUppercase" :label="$t('contact.villeRcs')" />
      <AkInputText v-model="currentValue.siret" :label="$t('contact.siret')" />
    </div>
  </div>

  <AkDialog
    ref="dialogDelete"
    :cancel-label="$t('no')"
    :title="$t('delete_dialog')"
    :validate-label="$t('yes')"
    width="450px"
    @validate="performDelete">
    <div class="flex items-center gap-2">
      <Icon class="text-red" icon="fluent:warning-20-regular" width="26" />
      <span>{{ $t('contact.deleteMemberWarning') }}</span>
    </div>
  </AkDialog>
</template>
