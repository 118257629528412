export default {
  data() {
    return {
      prospectSourceConst: [
        {label: "Apporteur d'affaires", value: 'APPORTEUR_D_AFFAIRES'},
        {label: 'Articles dans les journaux', value: 'ARTICLES_DANS_LES_JOURNAUX'},
        {label: 'Bouche à oreille', value: 'BOUCHE_A_OREILLE'},
        {label: 'Client réseau (MS/NAD/MAD/Aprolliance)', value: 'CLIENT_RESEAU_'},
        {label: "Club d'affaires / Réseau", value: 'CLUB_D_AFFAIRES_RESEAU'},
        {label: 'Foires/salons/animations commerciales', value: 'FOIRES_SALONS_ANIMATIONS_COMMERCIALES'},
        {label: 'Membre du réseau', value: 'MEMBRE_DU_RESEAU'},
        {label: 'Pôle emploi', value: 'POLE_EMPLOI'},
        {label: 'Prescripteurs (commerces de proximité, médecins, …)', value: 'PRESCRIPTEURS'},
        {label: 'Réseaux sociaux', value: 'RESEAUX_SOCIAUX'},
        {label: 'Site web', value: 'SITE_WEB'},
        {label: 'Presse', value: 'PRESSE'},
        {label: 'Véhicule', value: 'VEHICULE'},
        {label: 'Vitrine', value: 'VITRINE'},
        {label: 'Téléphone', value: 'TELEPHONE'},
        {label: 'Flyer', value: 'FLYER'},
        {label: 'E-mail', value: 'E_MAIL'},
        {label: 'Pas de source de connaissance connue', value: 'PAS_DE_SOURCE_DE_CONNAISSANCE_CONNUE'},
      ],
    };
  },
  methods: {
    displayForProspectSourceConst(value, returnLabel = '') {
      let result = this.prospectSourceConst.find((el) => el.value === value);
      return result ? result.label : returnLabel;
    },
  },
};
