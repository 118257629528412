export default {
  data() {
    return {
      prospectDisponibiliteConst: [
        {label: 'Moins de 15 jours', value: 'M15'},
        {label: 'Moins de 30 jours', value: 'M30'},
        {label: 'Moins de 45 jours', value: 'M45'},
        {label: 'Moins de 60 jours', value: 'M60'},
        {label: 'Moins de 90 jours', value: 'M90'},
        {label: 'Moins de 120 jours', value: 'M120'},
        {label: 'Moins de 180 jours', value: 'M180'},
        {label: "Toute l'année", value: 'YEAR'},
        {label: this.$t('undefined'), value: null},
      ],
    };
  },
  methods: {
    displayForProspectDisponibiliteConst(value) {
      let result = this.prospectDisponibiliteConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
