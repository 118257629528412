export default {
  data() {
    return {
      identiteContactConst: [
        {label: 'Propriétaire', value: 'OWNER'},
        {label: 'Locataire', value: 'LOCATAIRE'},
        {label: this.$t('undefined'), value: undefined},
      ],
      identiteContactConstForProspect: [
        {label: 'Propriétaire', value: 'OWNER'},
        {label: 'Locataire', value: 'LOCATAIRE'},
      ],
    };
  },
  methods: {
    displayForIdentiteContactConst(value) {
      let result = this.identiteContactConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getIdentiteContactForProspect() {
      return this.identiteContactConstForProspect;
    },
  },
};
