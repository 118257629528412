export default {
  data() {
    return {
      prospectPriorityConst: [
        {label: '1 (élevé)', value: 'HIGH'},
        {label: '2 (intermédiaire)', value: 'INTERMEDIATE'},
        {label: '3 (basse)', value: 'LOW'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForProspectPriorityConst(value) {
      let result = this.prospectPriorityConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
