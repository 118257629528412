import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  users() {
    return Api()
      .get('/users')
      .then((res) => res.data);
  },
  user(id) {
    return Api()
      .get('/user/' + id)
      .then((res) => res.data);
  },
  userDisplay(id) {
    return Api()
      .get('/user/' + id + '/display')
      .then((res) => res.data);
  },
  autocomplete(filter) {
    return Api()
      .get('/users/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  create(user) {
    return Api()
      .post('/user', user)
      .then((res) => res.data);
  },
  update(user) {
    return Api()
      .put('/user/' + user.id, user)
      .then((res) => res.data);
  },
  delete(user) {
    return Api()
      .delete('/user/' + user.id)
      .then(() => user);
  },
  formatRes(e) {
    return e;
  },
};
