import {goBackTo} from '@utils';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('details'),
          id: 'prospectDetails',
          hidden: this.isOwner() || !this.hasPermission(PermissionMap.PROSPECT.VIEW),
          active: true,
        },
        {
          name: this.$t('prospect.activity'),
          id: 'prospectActivity',
          hidden: this.isOwner(),
        },
      ],
    };
  },
  methods: {},
};
