export default {
  data() {
    return {
      regimeJuridiqueConst: [
        {label: this.$t('accommodation.immeubleRegimeCopro'), value: 'IMMEUBLE_REGIME_COPRO'},
        {label: this.$t('accommodation.maisonRegimeCopro'), value: 'MAISON_REGIME_COPRO'},
        {label: this.$t('accommodation.noRegimeCopro'), value: 'NO_REGIME_COPRO'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForRegimeJuridiqueConst(value) {
      let result = this.regimeJuridiqueConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
