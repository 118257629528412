export default {
  data() {
    return {
      prospectStatusConst: [
        {label: 'Actif', value: 'ACTIF'},
        {label: 'Nouveau', value: 'NOUVEAU'},
        {label: 'Perdu', value: 'PERDU'},
      ],
    };
  },
  methods: {
    displayForProspectStatusConst(value) {
      let result = this.prospectStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
